// Override default variables before the import
$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$primary:       #000000;
$secondary:     #8c1414;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$dark:          #5A7684;
$light:         #efefef;
// $light:         $gray-100;
// $dark:          $gray-900;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
